import React, { Fragment } from "react";
import MainReport from "./MainReport";

const ReportDeveloper = () => {
    return (
        <Fragment>
            <MainReport />
        </Fragment>
    )
};

export default ReportDeveloper;