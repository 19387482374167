import React, { Fragment } from "react";
import MainTask from "./MainTask";

const DagTask = () => {
    return (
        <Fragment>
            <MainTask />
        </Fragment>
    )
};

export default DagTask;