import React from 'react'

const WaterfallChartComponent=(props)=> {
    return (
        <div>
           <b>Waterfall Chart</b> 
        </div>
    )
}

export default WaterfallChartComponent
